<template>
	<div>
		<AddHeaderTitle :Header="Header" />
		<b-col md="12">
		<AppHeaderInfoList :infos="listItem" />
		</b-col>
		<b-row>
			<b-col md="7">
				<b-tabs>
					<b-tab v-for="l in languages" :key="l" :title="l">
						<template #title>
						<b-img
							:src="getFlag(l)"
							height="14px"
							width="22px"
							alt=""
						/>
						<span class="ml-50 text-body">{{ l }}</span>
						</template>
						<b-row>
							<b-col md="12">
								<b-list-group>
									<b-list-group-item
										v-for="item in listItems"
										:key="item._id"
										class="d-flex justify-content-between align-items-center"
									>
										<b-col md="1">
											<feather-icon
												v-if="getDataByLanguage(item.values, l, 't') === true"
												icon="CheckIcon"
												class="mr-50"
												stroke="green"
												v-b-tooltip.hover.bottom="'Traduction faite'"
											/>
											<feather-icon
												v-else
												icon="XCircleIcon"
												class="mr-50"
												stroke="red"
												v-b-tooltip.hover.bottom="'Traduction non faite'"
											/>
										</b-col>
										<b-col md="11">
											<b-row>
												<b-col md="11">
													<b-input-group
													>
														<b-form-input
															
															
															size="sm"
															:value="getDataByLanguage(item.values, l)"
															@input="name=$event"
															
														/>
														<b-input-group-append>
															<b-button
																variant="success"
																size="sm"
																@click="editLang(listItem.key,item._id,l, name )"
															>
																<!-- Add @click API end-point-->
																Editer
															</b-button>
														</b-input-group-append>
													</b-input-group>
												</b-col>
												<b-col md="1">
													<b-button
													v-ripple.400="'rgba(113, 102, 240, 0.15)'"
													variant="outline-danger"
													class="btn-icon"
													size="sm"
													@click="deleteItemByKey(listItem.key,item._id,getDataByLanguage(item.values, l))"
													>
														<feather-icon icon="Trash2Icon" />
													</b-button>
												</b-col>
											</b-row>
										</b-col>
										
									</b-list-group-item>
								</b-list-group>
							</b-col>
						</b-row>
						<!-- modal login-->
						<b-modal
						cancel-variant="outline-success"
						ok-variant="success"
						ok-title="Valider"
						cancel-title="Annuler"
						v-model="modal"
						centered
						title="Créer un nouvel item"
						@ok="newItem(listItem.key,l,newValue)"
						>
							<b-form>
								<b-form-group>
									<label for="lang">Langue</label>
								<b-form-input
								:value="l"
								disabled
								/>
								</b-form-group>
								<b-form-group>
									<label for="value">Item de liste</label>
								<b-form-input
								v-model="newValue"
								/>
								</b-form-group>
							</b-form>
						</b-modal>
					</b-tab>
				</b-tabs>
			</b-col>
			<b-col md="2">
				<b-button
				class="mt-5"
				v-ripple.400="'rgba(113, 102, 240, 0.15)'"
				variant="primary"
				@click="openModal"
				size="sm"
				>
					<feather-icon
					icon="PlusIcon"
					class="mr-50"
					/>
					<span class="align-middle">Ajouter</span>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import {
	VBTooltip,
	BTabs,
	BTab,
	BListGroup,
	BListGroupItem,
	BFormInput,
	BCol,
	BRow,
	BInputGroup,
	BInputGroupAppend,
	BButton,
	BImg,
	BModal, BForm, BFormGroup,

} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoList from "@/app/views/components/AppHeaderInfoList.vue";
import { List } from "@/mixins/list.js";
import { Config } from "@/mixins/config.js";

export default {
	name: "configuration-list-item",
	components: {
		AddHeaderTitle,
		BInputGroupAppend,
		BInputGroup,
		BFormInput,
		BTabs,
		BTab,
		BListGroup,
		BListGroupItem,
		AppHeaderInfoList,
		BButton,
		BCol,
		BRow,
		BImg,
		BModal, BForm,BFormGroup
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple,
	},
	mixins: [List, Config],
	data() {
		return {
			Dessert:"",
			name:"",
			modal: false,
			items: [],
			listItems: [],
			languages: [],
			newValue:'',
			Header: {
			config: "configuration",
			listLink: "/configuration/list/object",
			list: "liste-objet",
			detailLink: "",
			detail: "liste-item",
			},
			listKey: this.$route.params.id,
			listItem: {},
			langArray: [{
				lang: "fr_FR",
				img : require('@/assets/images/flags/fr.png')
				},
				{
				lang: "en_EN",
				img : require('@/assets/images/flags/en.png')
				},
				{
				lang: "es_ES",
				img : require('@/assets/images/flags/es.png')
				},
			],
		};
	},
	async mounted() {
		await this.getLanguages();
		await this.loadListItem();
	},
	methods: {
		getDataByLanguage(items, language, output = "value") {
			language;
			const [item] = items.filter((d) => (language = d.lang));
			switch (output) {
				case "t":
					return item.t;
				case "value":
					return item.value;
			}
		},
		async deleteItemByKey(key, id, value) {
			if (
                await this.$sweetConfirmation({
					value: value
                })
            ) {
				let loader = this.$loading.show({
					container: this.$refs.formContainer,
					canCancel: true,
				});
				try {
					const response = await this.deleteItem(key, id);
					if (response.status === 1 ) {
						this.$sweetNotif("Suppression réussie");	
						this.loadListItem(key)
					} else {
						this.$sweetError("AF-13");
					}
					loader.hide();
					} catch {
					loader.hide();
					this.$sweetError("AF-14");
				}
			}
		},
		async getLanguages() {
			const response = await this.getConfigDetail("LANGUAGES");
			if (response.status === 1) {
				this.languages = response.datas.values;
			} else {
				this.$sweetError("error network");
			}
		},
		async loadListItem() {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const datas = await this.getListItem(this.listKey);
				if (datas.status === 1) {
					this.listItem = datas.datas;
					this.listItems = datas.datas.listItems;
					
				} else {
					this.$toastError("Erreur de chargement vérifier votre connexion");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$toastError("Erreur de chargement vérifier votre connexion");
			}
		},
		getFlag(lang) {
			try {
				const flag = this.langArray.find(element => element.lang == lang);
				return flag.img  
			} catch {
				this.$sweetError("AF-12");
			}
		
		},
		async editLang(key,id,l, data) {
			let loader = this.$loading.show({
			container: this.$refs.formContainer,
			canCancel: true,
			});
			try {
			const response = await this.editItem(key,id,l, data);
			if (response.status === 1 ) {
				this.$sweetNotif("Modification réussie");	
				this.loadListItem(key)
			} else {
				this.$sweetError("AF-13");
			}
			loader.hide();
			} catch {
			loader.hide();
			this.$sweetError("AF-14");
			}
		},
		async newItem(key, l, value) {
			let loader = this.$loading.show({
			container: this.$refs.formContainer,
			canCancel: true,
			});
			try {
			const response = await this.addItem(key, l, value)
			if (response.status === 1 ) {
				this.$sweetNotif("Modification réussie")	
				this.loadListItem(key)
				this.newValue = ''
			} else {
				this.$sweetError("AF-13")
			}
			loader.hide();
			} catch {
			loader.hide();
			this.$sweetError("AF-14")
			}
		},
		openModal() {
			this.modal = true
		},
	}
}	
</script>
