<template>
	<b-card
		:img-src="backGround"
		:title="infos.key"
		style="min-height: 150px; max-width:150rem"
		overlay
		img-height="150rem"
		img-width="200"
	>
		<b-card-text>
			{{ infos.info }}
		</b-card-text>
	</b-card>
</template>

<script>
import { BCardText, BCard,} from "bootstrap-vue";

export default {
	name: "HeaderInfoMail",
	components: {
		BCard,
		BCardText,
	},
	data() {
		return {
			backGround : require('@/assets/images/banner/banner.png')
		};
	},
	props: ['infos'],

	methods: {},
};
</script>
